import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AnalyticsProvider } from '../interfaces/analytics-provider';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../definitions/interfaces';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService implements AnalyticsProvider {

    currentUserInfo?: UserInfo;


    /**
     * Initialize mixpanel.
     *
     * @param {string} userToken
     * @memberof MixpanelService
     */
    init(): void {
        mixpanel.init(environment.configuration.mixpanelToken);
    }

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    trackEvent(id: string, action: any = {}): void {
        mixpanel.track(id, { ...action, ...this.groups });
    }
    setUser(userId: string, properties: UserInfo): void {
        this.currentUserInfo = properties;
        mixpanel.identify(userId);
        mixpanel.people.set({ $name: properties.givenname, ...properties });
    }

    logout(): void {
        this.currentUserInfo = undefined;
        mixpanel.reset();
    }

    logPageView(pageName: string): void {
        mixpanel.track_pageview({ page: pageName, ...this.groups });
    }

    trackError(errorResponse: HttpErrorResponse): void {
        return;
    }

    logRequest(request: HttpRequest<any>, requestTime: number, message: string): void {
        return;
    }


    // Group keys must be event properties
    // https://docs.mixpanel.com/docs/data-structure/group-analytics#group-keys-tracked-as-event-properties
    private get groups() {
        return {
            organizationId: this.currentUserInfo?.currentOrganizationId,
            organizationUser: this.currentUserInfo ? {
                organizationId: this.currentUserInfo?.currentOrganizationId,
                userId: this.currentUserInfo?.userId
            } : undefined
        }
    }
}
