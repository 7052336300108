import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-icon-toggle-button',
  templateUrl: './custom-icon-toggle-button.component.html',
  styleUrls: ['./custom-icon-toggle-button.component.scss']
})
export class CustomIconToggleButtonComponent {

  @Input() checked = false;
  @Output() change = new EventEmitter<boolean>();

  onToggleChange() {
    this.change.emit((this.checked = !this.checked));
  }
}
