import { uuidWrapper } from 'src/app/shared/helpers/uuid';
import { Status } from '../definitions/enums';
import { IUnique } from '../definitions/interfaces';
import { ID } from '../definitions/types';
import { flattenWorkRoadsTree } from '../helpers/pattern-utils';
import { ElementDefinitionDetail } from '../interfaces/element-definition-detail.interface';
import { NULL_WORKROADS_TREE, WorkroadsTree } from '../interfaces/workroads-tree.interface';
import { BaseModel } from './base.model';
import { FieldDefinitionModel } from './field-definition.model';

const INITIAL = {
    quantity: 0,
    name: '',
    description: '',
    fieldDefinitions: [] as FieldDefinitionModel[],
    elementDefinitions: [] as ElementDefinitionModel[],
    admitChildren: true,
    isCountable: true,
    unicity: false,
    status: Status.Draft,
    createdBy: '',
    message: '',
    isMostRecent: false,
    structure: NULL_WORKROADS_TREE,
    hasStructure: false
};

export class ElementDefinitionModel extends BaseModel implements ElementDefinitionDetail {
    historyId?: ID;
    quantity!: number;
    name!: string;
    description!: string;
    fieldDefinitions!: FieldDefinitionModel[];
    elementDefinitions!: ElementDefinitionModel[];
    admitsChildren!: boolean;
    isCountable!: boolean;
    codeName!: string;
    unicity!: boolean;
    status!: Status;
    createdAt!: Date;
    createdBy!: string;
    message!: string;
    previousVersionId?: ID;
    isMostRecent?: boolean;
    structure!: WorkroadsTree;
    hasStructure!: boolean;

    constructor(obj: IUnique) {
        super(obj);
    }

    protected initialize(obj: Partial<ElementDefinitionDetail>): void {
        if (obj) {
            this.historyId = obj.historyId;
            this.name = obj.name ?? INITIAL.name;
            this.quantity = obj.quantity ?? INITIAL.quantity;
            this.description = obj.description ?? INITIAL.description;
            this.admitsChildren = obj.admitsChildren ?? INITIAL.admitChildren;
            this.isCountable = obj.isCountable ?? INITIAL.isCountable;
            this.fieldDefinitions = (
                (obj.fieldDefinitions ?? INITIAL.fieldDefinitions) as []
            ).map((item) => new FieldDefinitionModel(item));
            this.elementDefinitions = (
                (obj.elementDefinitions ?? INITIAL.elementDefinitions) as []
            ).map((item) => new ElementDefinitionModel(item));
            this.codeName = obj.codeName!;
            this.unicity = obj.unicity ?? INITIAL.unicity;
            this.status = obj.status ?? INITIAL.status;
            this.createdAt = obj.createdAt ?? new Date(Date.now());
            this.createdBy = obj.createdBy ?? INITIAL.createdBy;
            this.message = obj.message ?? INITIAL.message;
            this.previousVersionId = obj.previousVersionId;
            this.isMostRecent = obj.isMostRecent ?? INITIAL.isMostRecent;
            this.structure = this.getStructure(obj.structure);
            this.hasStructure = obj.hasStructure ?? INITIAL.hasStructure;
        }
    }

    getStructure(obj?: WorkroadsTree): WorkroadsTree {
        if (obj == null) {
            return NULL_WORKROADS_TREE;
        }
        const flattenedTree = [...flattenWorkRoadsTree(obj.childrenDefinitions)]
        
        const groupId = flattenedTree.length > 1 ? uuidWrapper.uuid() : undefined;
        let groupCount = 0;
        for (const node of flattenedTree) {
            if (Object.isExtensible(node)) {
                node.groupId = groupId;
                node.groupOrder = groupCount++;
            } else {
                console.log("Object not extensible");
            }
        }
        return obj;
    }
}

