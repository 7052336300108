import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    /**
     * @param router
     * @param errorHandler
     * Handle error in requests, if an Unauthorized error status is caught the
     * app will automatically redirect to authentication page
     */
    constructor(
        private router: Router,
        private errorHandler: ErrorHandler,
        private analyticsService: AnalyticsService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // we catch the error
            catchError((errorResponse: HttpErrorResponse) => {
                // log in console
                console.log({ errorResponse }); // eslint-disable-line no-console
                // log in Application Insights
                if (this.analyticsService) {
                    this.analyticsService.trackError(errorResponse);
                }

                // if the status is 401 Unauthorized
                if (errorResponse.status === HttpStatusCode.Unauthorized) {
                    // we redirect to authentication
                    this.router.navigateByUrl('/login');
                } else {
                    // else we notify the user
                    this.errorHandler.handleError(errorResponse);
                }
                return throwError(errorResponse);
            })
        );
    }
}
