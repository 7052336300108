import { IUnique } from '../definitions/interfaces';
import { BaseModel } from './base.model';
import { FieldDefinitionDetail } from '../interfaces/field-definition-detail.interface';
import {
    FieldDataType,
    RoundMode,
    TrackedChange,
    UnicityKind
} from '../definitions/enums';

export const DEFAULT_STEP = 1;
export const DEFAULT_OFFSET = 0;


export class FieldDefinitionModel extends BaseModel implements FieldDefinitionDetail {
    name!: string;
    description!: string;
    codeName!: string;
    dataType!: FieldDataType;
    value!: string;
    isCanonical!: boolean;
    unicityKind?: UnicityKind;
    trackedChange!: TrackedChange;
    step!: number;
    offset!: number;
    roundMode!: RoundMode;

    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: Partial<FieldDefinitionDetail>): void {
        if (obj) {
            this.name = obj.name as string;
            this.description = obj.description as string;
            this.codeName = obj.codeName as string;
            this.dataType = obj.dataType as FieldDataType;
            this.value = obj.value as string;
            this.isCanonical = obj.isCanonical ?? false;
            this.unicityKind = obj.unicityKind;
            this.trackedChange = obj.trackedChange as TrackedChange;
            this.step = obj.step ?? DEFAULT_STEP;
            this.offset = obj.offset ?? DEFAULT_OFFSET;
            this.roundMode = obj.roundMode ?? RoundMode.Floor;
        }
    }
}
