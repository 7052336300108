import { getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { Route } from 'src/app/core/constants/feature';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { HeaderItem } from 'src/app/modules/layout/interfaces/header-item';
import { fromRoot } from 'src/app/store';
import { ViewsDraftCreationInfo } from '../../interfaces/view-draft-creation.interface';
import { VersionHistoryView } from '../../interfaces/link-version-history.interface';
import { ViewToolbarView } from '../../interfaces/view-toolbar-view.interface';
import { viewsFeature } from '../reducers/views.reducer';
import { DEFAULT_REPORT_DEFINITION_LIST } from 'src/app/modules/list-tree-view/constants/report-default.constant';
import { ReportDefinition } from 'src/app/modules/list-tree-view/interfaces/report-definition.interface';
import { ID } from 'src/app/core/definitions/types';
import {
    SourceInfo,
    SourcesQueryParam
} from 'src/app/core/interfaces/source-info.interface';
import { decodeSourcesQueryParam } from 'src/app/core/helpers/string-helpers';
import { ViewsQueryParams } from '../../interfaces/views-query-params';

const { selectQueryParams } = getSelectors();

const {
    selectDrafts,
    selectSelected,
    selectViews,
    selectModified,
    selectCreationMode,
    selectExporting,
    selectDetailHeader,
    selectLoadingDetail,
    selectLinksForSelector,
    selectLoadingReport,
    selectSelectedReportPosition,
    selectLoadingLinksForSelector,
    selectVersionHistoryBuilds,
    selectVersionHistoryLoading,
    selectSelectedLink,
    selectVersionHistoryVisible,
    selectVersionHistoryBuildsLinkId,
    selectIsCompleteReportLoading,
    selectCompleteReportLoadedFor
} = viewsFeature;

const selectSourcesFromQueryParams = createSelector(
    selectQueryParams,
    (queryParams): SourcesQueryParam => {
        const sourcesEncoded = queryParams?.sources;
        if (!sourcesEncoded) return [] as SourcesQueryParam;
        let sources: any;

        try {
            sources = decodeSourcesQueryParam(sourcesEncoded);
        } catch (error) {
            console.warn('Failed to decode sources query param:', error); // eslint-disable-line no-console
            sources = [];
        }

        if (!Array.isArray(sources)) {
            console.warn('Invalid sources query param', sourcesEncoded); // eslint-disable-line no-console
            return [] as SourcesQueryParam;
        }

        const result: SourcesQueryParam = [];
        sources.forEach((source) => {
            if (!source.linkId) {
                console.warn('Invalid source', source); // eslint-disable-line no-console
            } else {
                result.push({
                    linkId: source.linkId,
                    buildId: source.buildId
                });
            }
        });
        return result;
    }
);

const selectSelectedSources = createSelector(
    selectSourcesFromQueryParams,
    selectLinksForSelector,
    (sourcesQueryParam, links) => {
        // This will ensure we only show correct links
        return sourcesQueryParam
            .map((source) => source.linkId)
            .filter((item) => links.some((link) => link.id === item));
    }
);

// This is the selection map
const selectVersionHistorySelection = createSelector(
    selectSourcesFromQueryParams,
    (sources): { [key: ID]: ID | undefined } => {
        const result: { [key: ID]: ID | undefined } = {};
        sources.forEach((param) => {
            result[param.linkId] = param.buildId;
        });
        return result;
    }
);

const selectViewDraftCreationInfoFromUrl = createSelector(
    selectQueryParams,
    fromRoot.selectCurrentFeature,
    (queryParams, currentFeature): ViewsDraftCreationInfo | null => {
        if (currentFeature !== Route.views) return null;
        return {
            isDraft: !!queryParams?.new,
            duplicateCurrentView: !!queryParams?.duplicate
        };
    }
);

const selectViewDetailHeader = createSelector(
    selectSelected,
    (view: ViewDetail | null): HeaderItem | null => {
        if (!view) return null;
        return { name: view.title, description: view.description };
    }
);

const selectHeader = createSelector(
    selectViewDetailHeader,
    selectDetailHeader,
    (detailHeader, editedHeader): HeaderItem | null => {
        if (!detailHeader) return null;
        return editedHeader ?? detailHeader;
    }
);

const selectSelectedQueryParam = createSelector(selectQueryParams, (queryParams) => {
    return queryParams?.selected;
});

const selectToolbarView = createSelector(
    fromRoot.selectUserPermissions,
    selectViewDetailHeader,
    selectCreationMode,
    selectModified,
    selectSelected,
    selectExporting,
    (
        userPermission,
        detailHeader,
        creationMode,
        modified,
        selected,
        exporting
    ): ViewToolbarView => {
        return {
            userPermission,
            detailHeader,
            creationMode,
            modified,
            selected,
            exporting
        };
    }
);

const selectViewPageView = createSelector(
    selectLoadingDetail,
    selectSelected,
    (loadingDetail, selected) => ({
        loadingDetail,
        selected
    })
);

const selectCurrentReportDefinition = createSelector(
    selectSelected,
    selectSelectedReportPosition,
    (view, reportDefinitionPosition): ReportDefinition => {
        return (
            view?.reportDefinitions[reportDefinitionPosition] ??
            DEFAULT_REPORT_DEFINITION_LIST
        );
    }
);

/// Everything related to showing the table:

const selectReportView = createSelector(
    selectCurrentReportDefinition,
    (reportDefinition) => reportDefinition.reportView
);

const selectColumnOrder = createSelector(
    selectCurrentReportDefinition,
    (reportDefinition) => {
        return reportDefinition.columns;
    }
);

const selectHiddenColumns = createSelector(
    selectCurrentReportDefinition,
    (reportDefinition) => {
        return reportDefinition.hiddenColumns;
    }
);

const selectFilters = createSelector(
    selectCurrentReportDefinition,
    (reportDefinition) => {
        return reportDefinition?.filters ?? [];
    }
);

const selectReportDefinitions = createSelector(selectSelected, (view) => {
    return view?.reportDefinitions ?? [];
});

const selectVersionHistoryView = createSelector(
    selectSelectedLink,
    selectVersionHistoryBuilds,
    (link, builds): VersionHistoryView => {

        if (!link) {
            return {
                exportedBy: '',
                exportedFrom: '',
                exportedAt: new Date(),
                patternTitle: '',
                drawingName: '',
                totalAmount: 0,
                builds: []
            };
        }
        return {
            exportedBy: link?.exportedBy,
            exportedFrom: link?.exportedFrom,
            exportedAt: link?.exportedAt,
            patternTitle: link?.patternTitle,
            drawingName: link?.drawingName,
            totalAmount: builds.length,
            builds: builds
        }
    }
);

const selectVersionForSelectedLink = createSelector(
    selectVersionHistorySelection,
    selectSelectedLink,
    (versionHisotrySelection, selectedLink) => {
        if (!selectedLink) return null;
        return versionHisotrySelection[selectedLink.id];
    }
);

// This selector is used to know when we nagivate to views.
// Every time this selector emits a value the following will happen
// 1. We check the stored params in the session storage.
// This might seem redundant in cases like when we navigate using the side menu or when we change a source
const selectViewsQueryParams = createSelector(
    fromRoot.selectCurrentFeature,
    selectSelectedQueryParam,
    selectSourcesFromQueryParams,
    selectViewDraftCreationInfoFromUrl,
    (feature, selected, sources, draftCreationInfo): ViewsQueryParams | null => {
        if (feature !== Route.views) return null;
        return {
            selected,
            sources,
            new: !!draftCreationInfo?.isDraft,
            duplicate: !!draftCreationInfo?.duplicateCurrentView
        };
    }
);

export const fromViews = {
    selectDrafts,
    selectViewDetailHeader,
    selectViews,
    selectViewsQueryParams,
    selectSelectedQueryParam,
    selectViewDraftCreationInfoFromUrl,
    selectSelected,
    selectToolbarView,
    selectHeader,
    selectModified,
    selectViewPageView,
    selectLinksForSelector,
    selectCurrentReportDefinition,
    selectLoadingReport,
    selectFilters,
    selectReportView,
    selectReportDefinitionColumns: selectColumnOrder,
    selectHiddenColumns,
    selectSelectedReportPosition,
    selectReportDefinitions,
    selectLoadingLinksForSelector,
    selectSelectedSources,
    selectCreationMode,
    selectVersionHistoryView,
    selectVersionHistoryLoading,
    selectVersionHistoryVisible,
    selectSelectedLink,
    selectVersionHistoryBuildsLinkId,
    selectVersionForSelectedLink,
    selectVersionHistorySelection,
    selectSourcesFromQueryParams,
    selectVersionHistoryBuilds,
    selectIsCompleteReportLoading,
    selectCompleteReportLoadedFor
};
