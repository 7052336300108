import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconRegistryModule } from '../modules/mat-icon-registry/mat-icon-registry.module';
import { AccountResultPageComponent } from './components/account-result-page/account-result-page.component';
import { AceEditorComponent } from './components/ace-editor/ace-editor.component';
import { CloseTabComponent } from './components/close-tab/close-tab.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { CustomAutosizeInputComponent } from './components/custom-autosize-input/custom-autosize-input.component';
import { CustomButtonToggleComponent } from './components/custom-button-toggle/custom-button-toggle.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { CustomExpansionPanelComponent } from './components/custom-expansion-panel/custom-expansion-panel.component';
import { CustomIconMenuConfirmButtonComponent } from './components/custom-icon-menu-confirm-button/custom-icon-menu-confirm-button.component';
import { CustomIconMenuComponent } from './components/custom-icon-menu/custom-icon-menu.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { CustomMenuContainerComponent } from './components/custom-menu-container/custom-menu-container.component';
import { CustomMenuContentComponent } from './components/custom-menu-content/custom-menu-content.component';
import { CustomMenuComponent } from './components/custom-menu/custom-menu.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomSnackBarComponent } from './components/custom-snack-bar/custom-snack-bar.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ErrorWarningComponent } from './components/error-warning/error-warning.component';
import { ExpansionCardContainerComponent } from './components/expansion-card-container/expansion-card-container.component';
import { FlowFilterBuilderComponent } from './components/flow-filter-builder/flow-filter-builder.component';
import { FormErrorMessagesComponent } from './components/form-error-messages/form-error-messages.component';
import { GlobalEditorLayoutComponent } from './components/global-editor-layout/global-editor-layout.component';
import { IconTextComponent } from './components/icon-text/icon-text.component';
import { ImportListItemComponent } from './components/import-list-item/import-list-item.component';
import { InvalidTokenErrorPageComponent } from './components/invalid-token-error-page/invalid-token-error-page.component';
import { ItemInGroupComponent } from './components/item-in-group/item-in-group.component';
import { LinksSelectComponent } from './components/links-select/links-select.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { NodeActionsMenuComponent } from './components/node-actions-menu/node-actions-menu.component';
import { NodeShowChildrenButtonComponent } from './components/node-show-children-button/node-show-children-button.component';
import { NotSelectedItemComponent } from './components/not-selected-item/not-selected-item.component';
import { NumberComparisonInputComponent } from './components/number-comparison-input/number-comparison-input.component';
import { OneSectionLayoutComponent } from './components/one-section-layout/one-section-layout.component';
import { PasswordRequirementsComponent } from './components/password-requirements/password-requirements.component';
import { PasswordValidationLineComponent } from './components/password-validation-line/password-validation-line.component';
import { SectionsLayoutComponent } from './components/sections-layout/sections-layout.component';
import { TipTextComponent } from './components/tip-text/tip-text.component';
import { UnicityDisplayComponent } from './components/unicity-display/unicity-display.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { UserListInviteComponent } from './components/user-list-invite/user-list-invite.component';
import { UserMenuEntityComponent } from './components/user-menu/user-menu-entity/user-menu-entity.component';
import { UserMenuOptionComponent } from './components/user-menu/user-menu-option/user-menu-option.component';
import { UserMenuOrganizationComponent } from './components/user-menu/user-menu-organization/user-menu-organization.component';
import { UserMenuSectionComponent } from './components/user-menu/user-menu-section/user-menu-section.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { VersionHistoryItemCollapsedComponent } from './components/version-history-item-collapsed/version-history-item-collapsed.component';
import { VersionHistoryItemComponent } from './components/version-history-item/version-history-item.component';
import { VersionHistoryTimelineComponent } from './components/version-history-timeline/version-history-timeline.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { WarningMessageIconComponent } from './components/warning-message-icon/warning-message-icon.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { AutoGrowDirective } from './directives/auto-grow.directive';
import { AutoScrollToSelectedDirective } from './directives/auto-scroll-to-selected.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ContextMenuDirective } from './directives/context-menu.directive';
import { CustomPropertiesDirective } from './directives/custom-property.directive';
import { ScrollShadowDirective } from './directives/scroll-shadows.directive';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ElementTypePipe } from './pipes/element-type.pipe';
import { FrameworkImagePipe } from './pipes/framework-image.pipe';
import { NoNewlinesPipe } from './pipes/no-newlines.pipe';
import { TypeIconPipe } from './pipes/type-icon.pipe';
import { UnicityDisplayPipe } from './pipes/unicity-display.pipe';
import { GetInitialsService } from './services/get-initials.service';
import { SnackBarService } from './services/snack-bar.service';
import { ErrorMessagePipe } from './pipes/error-message.pipe';
import { DetailedSnackbarComponent } from './components/detailed-snackbar/detailed-snackbar.component';
import { CustomIconToggleButtonComponent } from './components/custom-icon-toggle-button/custom-icon-toggle-button.component';

@NgModule({
    declarations: [
        ClickOutsideDirective,
        CloseTabComponent,
        CustomButtonComponent,
        CustomButtonToggleComponent,
        CustomExpansionPanelComponent,
        CustomIconMenuComponent,
        CustomIconMenuConfirmButtonComponent,
        CustomInputComponent,
        CustomAutosizeInputComponent,
        CustomMenuComponent,
        CustomPropertiesDirective,
        CustomSelectComponent,
        CustomSnackBarComponent,
        DeleteConfirmationComponent,
        ExpansionCardContainerComponent,
        FlowFilterBuilderComponent,
        ItemInGroupComponent,
        ListItemComponent,
        LoaderComponent,
        NodeShowChildrenButtonComponent,
        NotSelectedItemComponent,
        NumberComparisonInputComponent,
        OneSectionLayoutComponent,
        PasswordRequirementsComponent,
        SectionsLayoutComponent,
        TipTextComponent,
        UserImageComponent,
        UserListInviteComponent,
        UserMenuComponent,
        UserMenuEntityComponent,
        UserMenuOptionComponent,
        UserMenuOrganizationComponent,
        UserMenuSectionComponent,
        ItemInGroupComponent,
        PasswordRequirementsComponent,
        CustomButtonToggleComponent,
        CustomMenuContentComponent,
        FlowFilterBuilderComponent,
        NumberComparisonInputComponent,
        CustomMenuContainerComponent,
        CustomMenuContentComponent,
        CustomMenuContainerComponent,
        CustomDatePipe,
        PasswordValidationLineComponent,
        ElementTypePipe,
        CodeEditorComponent,
        AutoGrowDirective,
        NodeActionsMenuComponent,
        UserNameComponent,
        FormErrorMessagesComponent,
        AccountResultPageComponent,
        ErrorPageComponent,
        AceEditorComponent,
        ErrorWarningComponent,
        InvalidTokenErrorPageComponent,
        UnsavedChangesDialogComponent,
        VersionHistoryComponent,
        VersionHistoryItemComponent,
        IconTextComponent,
        VersionHistoryTimelineComponent,
        VersionHistoryItemCollapsedComponent,
        AutofocusDirective,
        VersionHistoryTimelineComponent,
        IconTextComponent,
        NoNewlinesPipe,
        AutoScrollToSelectedDirective,
        WarningMessageComponent,
        WarningMessageIconComponent,
        ScrollShadowDirective,
        NoNewlinesPipe,
        GlobalEditorLayoutComponent,
        LoadingPageComponent,
        UnicityDisplayPipe,
        UnicityDisplayPipe,
        TypeIconPipe,
        TypeIconPipe,
        UnicityDisplayComponent,
        ContextMenuDirective,
        ContextMenuComponent,
        ImportListItemComponent,
        FrameworkImagePipe,
        LinksSelectComponent,
        ErrorMessagePipe,
        DetailedSnackbarComponent,
        CustomIconToggleButtonComponent
    ],
    imports: [
        CommonModule,
        DragDropModule,
        FormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatIconRegistryModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatSnackBarModule,
        MatTableModule,
        ReactiveFormsModule,
        RouterModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTabsModule,
        MatChipsModule,
        NgxSkeletonLoaderModule.forRoot({
            animation: 'pulse',
            loadingText: ''
        }),
        MatDialogModule
    ],
    exports: [
        ClickOutsideDirective,
        CloseTabComponent,
        CustomButtonComponent,
        CustomButtonToggleComponent,
        CustomExpansionPanelComponent,
        CustomIconMenuComponent,
        CustomIconMenuConfirmButtonComponent,
        CustomInputComponent,
        CustomAutosizeInputComponent,
        CustomMenuComponent,
        CustomPropertiesDirective,
        CustomSelectComponent,
        DeleteConfirmationComponent,
        ExpansionCardContainerComponent,
        FlowFilterBuilderComponent,
        ListItemComponent,
        LoaderComponent,
        MatMenuModule,
        NodeShowChildrenButtonComponent,
        NotSelectedItemComponent,
        OneSectionLayoutComponent,
        PasswordRequirementsComponent,
        SectionsLayoutComponent,
        TipTextComponent,
        UserImageComponent,
        UserImageComponent,
        UserMenuComponent,
        UserMenuEntityComponent,
        UserMenuOptionComponent,
        UserMenuOrganizationComponent,
        UserMenuSectionComponent,
        CustomButtonToggleComponent,
        CustomMenuContentComponent,
        CustomMenuContainerComponent,
        FlowFilterBuilderComponent,
        CustomMenuContentComponent,
        CustomMenuContainerComponent,
        CustomDatePipe,
        ElementTypePipe,
        CodeEditorComponent,
        UserNameComponent,
        FormErrorMessagesComponent,
        NodeActionsMenuComponent,
        AccountResultPageComponent,
        ErrorWarningComponent,
        VersionHistoryComponent,
        VersionHistoryItemComponent,
        VersionHistoryTimelineComponent,
        ItemInGroupComponent,
        VersionHistoryItemCollapsedComponent,
        AutofocusDirective,
        ItemInGroupComponent,
        VersionHistoryComponent,
        VersionHistoryItemComponent,
        VersionHistoryTimelineComponent,
        AccountResultPageComponent,
        NoNewlinesPipe,
        AutoScrollToSelectedDirective,
        WarningMessageComponent,
        WarningMessageIconComponent,
        ScrollShadowDirective,
        NoNewlinesPipe,
        GlobalEditorLayoutComponent,
        UnicityDisplayPipe,
        TypeIconPipe,
        UnicityDisplayComponent,
        ContextMenuComponent,
        ContextMenuDirective,
        ImportListItemComponent,
        MatCheckboxModule,
        FrameworkImagePipe,
        LinksSelectComponent,
        ErrorMessagePipe,
        CustomIconToggleButtonComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: SharedModule,
            providers: [GetInitialsService, SnackBarService]
        };
    }
}
