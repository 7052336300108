import { createAction, props } from '@ngrx/store';
import { ID } from '../../../../core/definitions/types';
import { PatternDetail } from '../../../../core/interfaces/pattern-detail.interface';
import { NodeInfo } from '../../../linked-trees/interfaces/node-info';
import { AutocadTree } from '../../../../core/interfaces/autocad-tree.interface';
import { WorkroadsTree } from '../../../../core/interfaces/workroads-tree.interface';
import { LinkPushGroup } from '../../../../core/interfaces/link-push-group.interface';
import { Push } from '../../../../core/interfaces/push.interface';
import { InlineEditorId } from '../../../shared-nodes/interfaces/field-editor-id';
import { FieldReference, PropertyReference } from '../../types/click-on-reference-types';
import { DrawingTargetFramework } from 'src/app/core/definitions/enums';
import { BuildReportModel } from 'src/app/core/resources/build-resource-service/build.resource.service';

export const getPatterns = createAction('[Patterns] Get Patterns');

export const deletePatternDraft = createAction(
    '[Patterns] Delete Pattern Draft',
    props<{ id: ID }>()
);

export const createPatternDraft = createAction(
    '[Patterns] Create Pattern Draft from URL',
    props<{
        duplicate: boolean;
        draft: PatternDetail;
        queryParams?: any;
    }>()
);

export const selectPattern = createAction(
    '[Patterns] Select Pattern',
    props<{ id: ID | null }>()
);
export const setPattern = createAction(
    '[Patterns] Set Pattern',
    props<{ pattern: PatternDetail | null; isDraft: boolean }>()
);

/**
 * Is dispatched from the toolbar
 * The effect will need to merge the changed parts in the store.
 */
export const savePattern = createAction('[Patterns] Save Pattern');

export const restorePattern = createAction('[Patterns] Restore Pattern');

export const deletePatternOrDraft = createAction(
    '[Patterns] Delete Pattern or Draft',
    props<{ linksToDelete: ID[] }>()
);

export const getAssociatedLinks = createAction(
    '[Patterns] Get Associated Links to the selected pattern'
);

export const cleanLinkAssociations = createAction(
    '[Patterns] Clean the associated links for the pattern'
);

export const navigateToPatternList = createAction('[Patterns] Navigate to pattern list');

/**
 * @param referenceId is going to have the pushId when isLatest is not activated and linkId when it is activated
 */
export const navigateToPattern = createAction(
    '[Patterns] Navigate to pattern',
    props<{ id: ID; queryParams?: any }>()
);

export const navigateToArchivedPattern = createAction(
    '[Patterns] Navigate to archived pattern',
    props<{ id: ID; queryParams?: any }>()
);

export const navigateToArchivedPatternList = createAction(
    '[Patterns] Navigate to archived pattern list'
);

export const getBlockDefinitions = createAction('[Patterns] Get block definitions');

export const getPushGroup = createAction(
    '[Patterns] Get push group',
    props<{ id: ID }>()
);

export const changeDetailHeader = createAction(
    '[Patterns] Update pattern detail header',
    props<{ patternChanged: { title: string; description: string } }>()
);

export const patternDetailDiscard = createAction(
    '[Patterns] Discard new changes in the patterns',
    props<{ discard: boolean }>()
);

/**
 * The run check handles if a call to /check is made or not.
 */
export const patternTreeChange = createAction(
    '[Patterns] Autocad Tree Changed',
    props<{
        autocadTree: AutocadTree;
        workRoadsTree: WorkroadsTree;
        runChecks: boolean;
    }>()
);

export const setPatternModified = createAction(
    '[Patterns Filters] Set modified state to pattern',
    props<{ modified: boolean }>()
);

/**
 * @param autoVersion sets the isLatest property of the pattern to true or false
 */
export const pushSelectionChange = createAction(
    '[Patterns Effect] Change Reference Link / Push to pattern',
    props<{
        id: ID | undefined;
        autoVersion: boolean;
        targetFramework: DrawingTargetFramework;
    }>()
);

export const setEditingField = createAction(
    '[Field Editor] Set editing field',
    props<{
        nodeInfo: NodeInfo;
        id: InlineEditorId;
        nodeDisplayName: string;
        initialValue: string;
        codeName: string;
    }>()
);

export const clickOnReference = createAction(
    '[Autocad Node] Click on Reference',
    props<{ nodeInfo: NodeInfo } & PropertyReference>()
);

export const clickOnReferenceField = createAction(
    '[Workroads Pattern Node] Click on Reference Field',
    props<{ nodeInfo: NodeInfo } & FieldReference>()
);

export const clickOnValue = createAction(
    '[Autocad Node] Click on Value',
    props<{ value: string }>()
);

export const clickOnReferenceConsumed = createAction(
    '[Global Editor] Click on reference consumed'
);

export const disableEditingField = createAction('[Global Editor] Close editing field');
export const disableEditingFieldInline = createAction(
    '[Field Editor] Close editing field'
);

export const selectPush = createAction(
    '[Push Select] Select push',
    props<{ id: ID | null }>()
);

export const getPushesForSelect = createAction('[Push Select] GET pushes for dropdown');

export const getPushes = createAction('[Push API] GET All Pushes');

export const getPushesSuccess = createAction(
    '[Push API] GET All Pushes Success',
    props<{ linkGroup: LinkPushGroup }>()
);

export const getPushesFail = createAction(
    '[Push API] GET All pushes fails',
    props<{ error: string }>()
);

export const getPush = createAction('[Push API] Get Push', props<{ id: ID }>());

export const getPushSuccess = createAction(
    '[Push API] Get Push Success',
    props<{ push: Push }>()
);

export const getPushFailure = createAction(
    '[Push API] Get push fails',
    props<{ error: string }>()
);

export const pushChange = createAction(
    '[Pattern Page] Push Selected Changed',
    props<{ id: ID; autoVersion: boolean }>()
);

export const setLoading = createAction(
    '[Pattern Api] Set a value for the push loader',
    props<{ loading: boolean }>()
);

export const setTryingPattern = createAction(
    '[Pattern Toolbar] Use the selected pattern to try build',
    props<{ tryingPattern: boolean }>()
);

export const createLink = createAction(
    '[Pattern Toolbar] Use the selected pattern to create link'
);

export const createLinkFail = createAction(
    '[Pattern Toolbar] Create link Failed'
);

export const createLinkSuccess = createAction(
    '[Pattern Toolbar] Create link Success',
    props<{ buildId: ID, linkId: ID }>()
);