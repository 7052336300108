<div class="container" [class.error]="error">
    <app-ace-editor
        [inline]="inline"
        [editorId]="editorId"
        [defaultExpression]="defaultExpression"
        [showOverrideControls]="showOverrideControls"
        [nodeAutocompleteContent]="nodeAutocompleteContent"
        [override]="override"
        (editorChange)="onValueChange($event)"
    ></app-ace-editor>
</div>
