<span *ngIf="DEBUG">{{ currentlySelectedToken | json }}</span>
<div
    *ngIf="showOverrideControls && isDifferent"
    class="default-expression"
    [class.override]="override"
>
    <span>
        {{ defaultExpression }}
    </span>
</div>
<div [class.dim]="showOverrideControls && !override && isDifferent">
    <div [id]="editorId" class="ace-editor"  #editor></div>
</div>
