import { Injectable } from '@angular/core';
import { AnalyticsProvider } from '../interfaces/analytics-provider';
import { MixpanelService } from './mixpanel.service';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../definitions/interfaces';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private providers: AnalyticsProvider[] = [];

    constructor(private mixpanelService: MixpanelService) {
        if (environment.configuration.saveInsights) {
            this.providers = [mixpanelService];
        }
    }

    init() {
        this.providers.forEach((provider) => provider.init());
    }

    trackEvent(eventName: string, properties?: any): void {
        this.providers.forEach((provider) => provider.trackEvent(eventName, properties));
    }

    setUser(userId: string, properties: UserInfo): void {
        this.providers.forEach((provider) => provider.setUser(userId, properties));
    }

    logout() {
        this.providers.forEach((provider) => provider.logout());
    }

    logPageView(pageName: string): void {
        this.providers.forEach((provider) => provider.logPageView(pageName));
    }

    trackError(errorResponse: HttpErrorResponse) {
        this.providers.forEach((provider) => provider.trackError(errorResponse));
    }

    logRequest(reqStartDate: number, request: HttpRequest<any>, msg: string) {
        if (!request.url.includes('api')) return;

        const requestTime = Date.now() - reqStartDate;
        const message = `${request.url}: ${msg}!. Request completed in ${requestTime}ms`;

        this.providers.forEach((provider) =>
            provider.logRequest(request, requestTime, message)
        );
    }
}
