import { ElementDefinitionDetail } from "src/app/core/interfaces/element-definition-detail.interface";
import { FieldDefinitionDetail } from "src/app/core/interfaces/field-definition-detail.interface";
import { CODE_NAME_REGEX } from "src/app/shared/consts";

export function validateElement(element: ElementDefinitionDetail): boolean {
    if (!element) return false;

    if (
        !isStringValid(element.codeName, (codeName) =>
            CODE_NAME_REGEX.test(codeName)
        )
    ) {
        // we used to update the state here to put the errors in the store, but we moved this to a selector
        return false;
    }

    return (
        isStringValid(element.name) &&
        hasValidFields(element.fieldDefinitions)
    );
}


export function isStringValid(str: string, func?: (val: string) => boolean): boolean {
    return !!str && str.trim() !== '' && (!func || func(str));
}

export function hasValidFields(fields: FieldDefinitionDetail[]): boolean {
    return (
        (fields.length > 0 && fields.every((field) => validateField(field))) ||
        fields.length === 0
    );
}

export function validateField(field: FieldDefinitionDetail): boolean {
    return (
        isStringValid(field.name) &&
        isStringValid(field.codeName, (codeName) =>
            CODE_NAME_REGEX.test(codeName)
        )
    );
}