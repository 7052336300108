import { Pipe, PipeTransform } from '@angular/core';
import { ElementType, ElementTypeEnum } from 'src/app/modules/patterns/enums/elementType';

@Pipe({
    name: 'elementType'
})
export class ElementTypePipe implements PipeTransform {
    transform(value: ElementType, ...args: unknown[]): string {
        if (!value) {
            return '';
        }
        if (!(value.type in ElementTypeEnum)) {
            return value.type.toString();
        }
        if (value.type === ElementTypeEnum.GenericXmlObject) {
            return value.label ?? "";

        }
        return this.splitCamelCase(ElementTypeEnum[value.type]);
    }

    splitCamelCase(text: string) {
        return text.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
}
