import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics.service';

@Injectable()
export class ResponseLogHandlerInterceptorTsInterceptor implements HttpInterceptor {
    constructor(private analyticsService: AnalyticsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const reqStartDate = Date.now();
        let msg = '';
        return next.handle(req).pipe(
            tap(
                (event) => (msg = this.handleResponse(event)),
                (error) => (msg = this.handleError(error))
            ),
            finalize(() => this.logRequest(req, reqStartDate, msg))
        );
    }

    private handleResponse(event: any) {
        if (event instanceof HttpResponse) {
            const msg = `succeeded with status: ${event.status}: ${event.statusText}`;
            return msg;
        }
        return '';
    }

    private handleError(error: HttpErrorResponse) {
        const errorMsg = `${error.error} with status: ${error.status} and status text: ${error.statusText}`;
        return errorMsg;
    }

    private logRequest(req: HttpRequest<any>, reqStartDate: number, msg: string) {
        this.analyticsService.logRequest(reqStartDate, req, msg);
    }
}
