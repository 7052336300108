<!-- Toolbar Mode -->
<ng-container *ngIf="toolbarMode">
    <div class="toolbar-errors-container">
        <app-custom-expansion-panel
            class="errors-toolbar-expansion-panel"
            *ngIf="errorCountLabel || warningCountLabel"
        >
            <div class="title">
                <ng-container *ngTemplateOutlet="header"></ng-container>
            </div>
            <div class="content">
                <ng-container *ngTemplateOutlet="body"></ng-container>
            </div>
        </app-custom-expansion-panel>
    </div>
</ng-container>

<!-- Use Expand Mode -->
<app-custom-expansion-panel
    *ngIf="useExpand && !toolbarMode"
    class="errors-expansion-panel"
>
    <div class="title">
        <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
    <div class="content">
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
</app-custom-expansion-panel>

<!-- Regular Mode -->
<ng-container *ngIf="!useExpand && !toolbarMode">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngTemplateOutlet="body"></ng-container>
</ng-container>

<ng-template #header>
    <div class="count">
        <span class="count-label error" *ngIf="errorCountLabel">
            <mat-icon
                class="cycle-error-icon error-icon"
                aria-hidden="true"
                aria-label="Error"
                fontIcon="error"
            ></mat-icon>
            {{ errorCountLabel }}
        </span>
        <span class="count-label warning" *ngIf="warningCountLabel">
            <mat-icon
                class="cycle-error-icon warning-icon"
                aria-hidden="true"
                aria-label="warning"
                fontIcon="warning"
            ></mat-icon>
            {{ warningCountLabel }}
        </span>
    </div>
</ng-template>
<ng-template #body>
    <div class="cycle-errors-container errors">
        <span class="error-message">
            <ul>
                <li class="error" *ngFor="let err of totalErrors ?? []">
                    {{ err | errorMessage }}
                </li>
                <li class="warning" *ngFor="let err of totalWarnings ?? []">
                    {{ err | errorMessage }}
                </li>
            </ul>
        </span>
        
    </div>
</ng-template>
