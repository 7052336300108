import { ElementDefinitionDetail } from "src/app/core/interfaces/element-definition-detail.interface";
import { NodeInfo } from "../../linked-trees/interfaces/node-info";
import { ClickOnReferenceInfo, ClickOnValueInfo } from "../../patterns/types/click-on-reference-types";
import { InlineEditorId } from "../interfaces/field-editor-id";
import * as patternsApiActions from 'src/app/modules/patterns/store/actions/patterns-api.action';
import * as patternsActions from 'src/app/modules/patterns/store/actions/patterns.action';
import * as sharedNodesActions from './shared-nodes.actions';
import { createFeature, createReducer, on } from "@ngrx/store";
import { EditingField } from "../interfaces/editing-field";

// This will hold common state for pattern editor and elements editor
export interface WorkroadsTreeEditorState {
    currentlyEditingField: EditingField | null;

    currentClickOnReferenceInfo: ClickOnReferenceInfo | ClickOnValueInfo | null;
    elementDefinitions: ElementDefinitionDetail[];



}

export const initialState: WorkroadsTreeEditorState = {
    currentlyEditingField: null,
    currentClickOnReferenceInfo: null,
    elementDefinitions: [],
}


const workroadsTreeEditorReducer = createReducer(
    initialState,
    on(patternsApiActions.getPatternById, (state) => ({
        ...state,
        currentlyEditingField: null,
    })),
    on(patternsApiActions.getArchivedPatternById, (state) => ({
        ...state,
        currentlyEditingField: null,
    })),
    on(patternsActions.setPattern, (state) => {
        return {
            ...state,
            currentlyEditingField: null,
        };
    }),
    on(patternsApiActions.updatePatternSuccess, (state) => ({
        ...state,
        currentlyEditingField: null
    })),
    on(patternsActions.patternDetailDiscard, (state, { discard }) => {
        return {
            ...state,
            currentlyEditingField: discard ? null : state.currentlyEditingField,
        }
    }),
    on(
        sharedNodesActions.setEditingField,
        (state, editingField) => {
            return {
                ...state,
                currentlyEditingField: editingField
            }
        }
    ),
    on(
        sharedNodesActions.disableEditingField,
        sharedNodesActions.disableEditingFieldInline,
        (state) => {
            return {
                ...state,
                currentlyEditingField: null
            };
        }
    ),
    on(sharedNodesActions.clickOnValue, (state, { value }) => {
        return {
            ...state,
            currentClickOnReferenceInfo: {
                value
            }
        };
    }),
    on(sharedNodesActions.clickOnReferenceConsumed, (state) => {
        return {
            ...state,
            currentClickOnReferenceInfo: null
        };
    }),
    on(
        sharedNodesActions.clickOnReference,
        (state, { propertyName, groupName, nodeInfo }) => {
            return {
                ...state,
                currentClickOnReferenceInfo: {
                    destination: nodeInfo,
                    origin: state.currentlyEditingField?.nodeInfo,
                    propertyName,
                    groupName
                }
            };
        }
    ),
    on(
        sharedNodesActions.clickOnReferenceField,
        (state, { fieldDefinitionName, fieldDefinitionCodeName, nodeInfo }) => {
            return {
                ...state,
                currentClickOnReferenceInfo: {
                    destination: nodeInfo,
                    origin: state.currentlyEditingField?.nodeInfo,
                    fieldDefinitionName,
                    fieldDefinitionCodeName
                }
            };
        }
    ),
    on(sharedNodesActions.getElementDefinitionForTreeEditor, (state) => ({
        ...state,
        elementDefinitions: []
    })),
    on(
        sharedNodesActions.getElementDefinitionForTreeEditorSuccess,
        (state, { elementDefinitions }) => ({
            ...state,
            elementDefinitions
        })
    ),
    on(
        sharedNodesActions.getElementDefinitionForTreeEditorFail,
        (state) => ({
            ...state
        })
    )
)



export const workroadsTreeEditorFeature = createFeature({
    name: 'workroadsTreeEditor',
    reducer: workroadsTreeEditorReducer,
});