<div
    class="detailed-notification"
    [class.success]="data.success"
    [class.error]="!data.success"
>
    <span class="icon"
        ><mat-icon *ngIf="data.success" fontSet="material-icons-outlined">check_circle</mat-icon>
        <mat-icon *ngIf="!data.success" fontSet="material-icons-outlined">error</mat-icon>
        </span
    >
    <div class="right-container">
        <span class="message">{{ data.message }}</span>
        <a [href]="data.link" target="_blank" class="link">{{ data.linkLabel }}</a>
    </div>
</div>
