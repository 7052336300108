<ng-container *ngIf="view$ | async as data">
    <app-pattern-toolbar-form
        [ableToEdit]="!data.readonlyMode"
        [creating]="!!data.creationMode"
    >
    </app-pattern-toolbar-form>
    <ng-container *ngIf="data.patternDetailHeader">
        <div class="right-container" *ngIf="!data.readonlyMode">
            <ng-container
                *ngIf="{ hasErrors: !!(allPatternErrors$ | async)?.length } as errors"
            >
                <app-cycle-errors
                    [validationState]="patternValidationResult$ | async"
                    [toolbarMode]="true"
                ></app-cycle-errors>
                <div
                    class="button-action-container"
                    *ngIf="data.creationMode || data.modified"
                    data-test-id="save-buttons"
                >
                    <app-custom-button
                        color="primary"
                        text="Discard"
                        class="small"
                        type="stroked"
                        size="small"
                        (buttonClick)="onDiscard()"
                    >
                    </app-custom-button>
                    <app-custom-button
                        size="small"
                        [color]="errors.hasErrors ? 'warn' : 'primary'"
                        type="flat"
                        [disabled]="!data.modified || !isFormValid"
                        [text]="data.creationMode ? 'Create' : 'Save Changes'"
                        (buttonClick)="onSubmit()"
                    ></app-custom-button>
                </div>
                <div
                    class="button-action-container"
                    *ngIf="!data.modified && !data.creationMode"
                >
                
                    <app-custom-button
                        *ngIf="!(tryingPattern$ | async); else exitTryPattern"
                        size="auto"
                        [color]="errors.hasErrors ? 'warn' : 'primary'"
                        text="Try Pattern"
                        type="flat"
                        (buttonClick)="onTryPattern(true)"
                        [disabled]="!(isPushSelected$ | async)"
                        class="try-pattern-button"
                    ></app-custom-button>
                    
                    <ng-template #exitTryPattern>
                        <app-custom-button
                            size="auto"
                            color="primary"
                            text="Exit Try Pattern Mode"
                            type="flat"
                            (buttonClick)="onTryPattern(false)"
                            class="try-pattern-button"
                        ></app-custom-button>
                        
                    </ng-template>
                </div>
                <div class="menu-container">
                    <app-custom-menu
                        *ngIf="!data.creationMode"
                        class="menu"
                        icon="more_vert"
                        iconColor="primary"
                        iconType="basic"
                        [iconStyle]="{
                            background: '#F5F5F5',
                            color: 'black',
                            width: '36px',
                            height: '36px'
                        }"
                        [actions]="menuActions"
                    >
                    </app-custom-menu>
                </div>
            </ng-container>
        </div>
        <div
            class="right-container"
            *ngIf="(isArchived$ | async) && (canRestore$ | async)"
        >
            <div class="button-action-container">
                <app-custom-button
                    size="auto"
                    color="primary"
                    text="Restore"
                    type="flat"
                    (buttonClick)="onRestorePattern()"
                    class="try-pattern-button"
                ></app-custom-button>
            </div>
        </div>
    </ng-container>
</ng-container>
