<div class="menu">
    <app-custom-button
        [matMenuTriggerFor]="menu"
        [color]="iconColor"
        [type]="iconType"
        size="auto"
        [icon]="icon"
        (buttonClick)="onMenuTrigger()"
        [styles]="iconStyle"
    ></app-custom-button>

    <mat-menu #menu="matMenu" class="custom-class" xPosition="before" yPosition="below">
        <ng-container *ngFor="let action of actions">
            <button
                *ngIf="!action.hidden"
                class="menu-element"
                [class.disabled]="action.disabled"
                mat-menu-item
                (click)="action.emitEvent()"
                [ngStyle]="action.style"
                [disabled]="!!action.disabled"
            >
                <ng-container *ngIf="!action.iconFirst; else actionIconFirst">
                    {{ action.label }}
                    <mat-icon *ngIf="!action.isSvgIcon">{{ action.icon }}</mat-icon>
                    <mat-icon *ngIf="action.isSvgIcon" [svgIcon]="action.icon"></mat-icon>
                </ng-container>
                <ng-template #actionIconFirst>
                    <mat-icon *ngIf="!action.isSvgIcon">{{ action.icon }}</mat-icon>
                    <mat-icon *ngIf="action.isSvgIcon" [svgIcon]="action.icon"></mat-icon>
                    {{ action.label }}
                </ng-template>
            </button>
        </ng-container>
    </mat-menu>
</div>
